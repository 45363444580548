import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router';
import {
  Modal,
  ModalHeader,
  ModalBody,
  NavItem,
  Nav,
  Row,
  Col,
  Label,
  TabContent,
  TabPane,
  NavLink,
  Button,
} from 'reactstrap';
import classnames from 'classnames';
import axios from 'axios';
import { useEnabledFeatureFlag } from '../../../utils/feature-flagging/FeatureFlagProvider';
import FeatureFlags from '../../../utils/feature-flagging/FeatureFlags';
import EmployerReconCalendar from './EmployerReconCalendar';
import CreateReconEventModal from './CreateEvent';
import EmployerHRCRecons from './EmployerHRCRecons';

const EmployerReconsPage = (props) => {
  const isManageReconsEnabled = useEnabledFeatureFlag(
    FeatureFlags.ADMIN_PORTAL_AUTOMATED_ODP_INVOICING
  );

  const location = useLocation();

  const employerDetails = {
    payCycles: location.state.save_data.pay_cycles,
    branches: location.state.save_data.branches,
    employerName: location.state.save_data.company_name,
  };

  const [calendarEvents, setCalendarEvents] = React.useState([]);

  const employerId = location.pathname.split('/')[3];

  const [loading, setLoading] = React.useState(false);

  const [isCreateEvent, setIsCreateEvent] = React.useState(false);

  const [currentActiveTab, setCurrentActiveTab] = useState('1');

  const handleOnCloseModal = (reload) => {
    if (reload) {
      checkForRecons();
    }
  };

  const checkForRecons = useCallback(async () => {
    setLoading(true);

    axios
      .get(`/api/calendar_events?employerId=${employerId}`)
      .then((response) => {
        setCalendarEvents(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [employerId]);

  React.useEffect(() => {
    checkForRecons();
  }, [checkForRecons]);

  if (loading) {
    return (
      <Row className="my-3">
        <Col className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </Col>
      </Row>
    );
  }

  return (
    <>
      {isCreateEvent && (
        <CreateReconEventModal
          isOpen={isCreateEvent}
          setIsCreateEvent={setIsCreateEvent}
          payCycles={employerDetails.payCycles}
          branches={employerDetails.branches}
          employerName={employerDetails.employerName}
          calendarEvents={calendarEvents}
          onClose={handleOnCloseModal}
        />
      )}

      <div className="bg-primary">
        <Row className="p-4">
          <Col className="col-10">
            <Label className="h2 text-white">Employer Recons</Label>
          </Col>
          {isManageReconsEnabled && currentActiveTab === '2' && (
            <Col>
              <Button onClick={() => setIsCreateEvent(true)}>
                Create Event
              </Button>
            </Col>
          )}
        </Row>
      </div>

      <div>
        <Nav justified tabs>
          <NavItem>
            <NavLink
              style={{
                cursor: 'pointer',
                fontSize: '24px',
              }}
              onClick={() => setCurrentActiveTab('1')}
              className={classnames({
                active: currentActiveTab === '1',
              })}>
              HRC
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              style={{
                cursor: 'pointer',
                fontSize: '24px',
              }}
              onClick={() => setCurrentActiveTab('2')}
              className={classnames({
                active: currentActiveTab === '2',
              })}>
              EWA
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={currentActiveTab}>
          <TabPane tabId="1" className="p-4">
            <EmployerHRCRecons employerId={employerId} />
          </TabPane>

          <TabPane tabId="2" className="p-2">
            <EmployerReconCalendar employerId={employerId} />
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

export default EmployerReconsPage;
