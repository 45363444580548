import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import axios from 'axios';
import apiResponseHandler from '../../../../utils/apiResponseHandler';
import { environment } from '../../../../enviroment/enviroment';
import EmployeeLimits from '../../Advances/EmployeeEWALimits';
import { useEnabledFeatureFlag } from '../../../../utils/feature-flagging/FeatureFlagProvider';
import FeatureFlags from '../../../../utils/feature-flagging/FeatureFlags';

const REASONS = [
  {
    value: 1,
    label: '1. Transport',
  },
  {
    value: 2,
    label: '2. Groceries',
  },
  {
    value: 3,
    label: '3. Bills',
  },
  {
    value: 4,
    label: '4. Family/Kids',
  },
  {
    value: 5,
    label: '5. Shopping/entertainment',
  },
  {
    value: 6,
    label: '6. Emergency medical/repair expense',
  },
  {
    value: 7,
    label: '7. Other',
  },
];

const EmployeeAdvanceAdd = ({ employeeId }) => {
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [employee, setEmployee] = useState();
  const [employeeAdvanceData, setEmployeeAdvanceData] = useState({
    available_advance_balance: 0,
    available_advance_to_withdraw: 0,
    is_advance_permitted: false,
    permission_reason: 'Employee data is not complete',
    volume_and_value: {
      amount_used: 0,
      request_used: 0
    },
    instant: {
      amount_used: 0,
      request_used: 0
    },
    standard: {
      amount_used: 0,
      request_used: 0
    }
  });
  const [employeeLimits, setEmployeeLimits] = useState(null);
  const [amountRequested, setAmountRequested] = useState();
  const [status, setStatus] = useState();
  const [reason, setReason] = useState();
  const isDisplayNewEwaLimitsEnabled = useEnabledFeatureFlag(
    FeatureFlags.DISPLAY_NEW_EWA_LIMITS
  );

  useEffect(() => {
    getEmployeeAdvanceData(employeeId);
    getEmployeeData(employeeId);
  }, [employeeId]);

  useEffect(() => {
  }, [employeeLimits]);

  const getEmployeeData = (id) => {
    setLoading(true);
    axios.get(`${environment.baseUrl}/employees/${id}`).then((res) => {
      setEmployee(res.data);
      setLoading(false);
      if (res.data && res.data.employer) {
        getEmployeeLimits(id, res.data.employer.id);
      } 
    });
  };

  const getEmployeeAdvanceData = (employeeId) => {
    setLoading(true);
    axios
      .get(`${environment.baseUrl}/employees/${employeeId}/advances/`)
      .then((res) => {
        setEmployeeAdvanceData({
          available_advance_balance: res.data.available_advance_balance || 0,
          available_advance_to_withdraw: res.data.available_advance_to_withdraw || 0,
          is_advance_permitted: res.data.is_advance_permitted || false,
          permission_reason: res.data.permission_reason || 'Employee data is not complete',
          volume_and_value: {
            amount_used: res.data.volume_and_value?.amount_used || 0,
            request_used: res.data.volume_and_value?.request_used || 0
          },
          instant: {
            amount_used: res.data.instant?.amount_used || 0,
            request_used: res.data.instant?.request_used || 0
          },
          standard: {
            amount_used: res.data.standard?.amount_used || 0,
            request_used: res.data.standard?.request_used || 0
          }
        });
        setLoading(false);
      })
      .catch((error) => {
        setEmployeeAdvanceData({
          available_advance_balance: 0,
          available_advance_to_withdraw: 0,
          is_advance_permitted: false,
          permission_reason: 'Error fetching employee data',
          volume_and_value: {
            amount_used: 0,
            request_used: 0
          },
          instant: {
            amount_used: 0,
            request_used: 0
          },
          standard: {
            amount_used: 0,
            request_used: 0
          }
        });
        setLoading(false);
      });
  };

  const getEmployeeLimits = (employee_id, employer_id) => {
    axios
      .get(`${environment.baseUrl}/odp_limits/config/?employee=${employee_id}`, {
        params: { employer_id }
      })
      .then((res) => {
        if (res.data && Object.keys(res.data).length > 0) {
          setEmployeeLimits(res.data, employeeAdvanceData);
        } else {
          setEmployeeLimits({});
        }
      })
      .catch((error) => {
        setEmployeeLimits(null);
        apiResponseHandler.handleApiError('Error', error);
      });
  };

  const createAdvance = () => {
    setIsSubmitting(true);
    axios
      .post(`${environment.baseUrl}/advancerequests/`, {
        employee: employeeId,
        amount_requested: amountRequested,
        status,
        reason,
        source_type: 'manual',
      })
      .then((res) => {
        Swal.fire({
          title: 'Advance request saved',
          icon: 'success',
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        setIsSubmitting(false);
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error', error);
        setIsSubmitting(false);
      });
  };

  return loading ? (
    <Col className="d-flex justify-content-center mt-3">
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </Col>
  ) : (
    <div className="p-3">
      <Row>
        <Col md="8" className="m-auto">
          <Card className="shadow">
            <CardBody>
            {isDisplayNewEwaLimitsEnabled && employeeLimits ? (
                <>
                  <EmployeeLimits 
                    employeeLimits={employeeLimits} 
                    employeeAdvanceData={employeeAdvanceData} 
                  />
                  <Row className="mt-4">
                    <Col>
                      <h2 className="mt-4 mb-3">
                        Bank Details
                      </h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Card>
                        <CardBody>
                          Bank account number (last 4 digits):{' '}
                          <span className="h3">
                            {employee && employee.bank_account_number
                              ? employee.bank_account_number.slice(-4)
                              : '-'}
                          </span>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row className="mt-4">
                    <Col>
                      <Row>
                        <Col>
                          <h3>
                            {employee
                              ? `${employee.first_name} ${employee.last_name}`
                              : '-'}
                          </h3>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Card>
                        <CardBody>
                          <h3>
                            R{' '}
                            {employeeAdvanceData &&
                              (employeeAdvanceData.available_advance_balance
                                ? employeeAdvanceData.available_advance_balance
                                : '-')}
                          </h3>
                          <small>Available advance balance</small>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <Card>
                        <CardBody>
                          <h4>Confirm details</h4>
                          Bank account number (last 4 digits):{' '}
                          <span className="h3">
                            {employee && employee.bank_account_number
                              ? employee.bank_account_number.slice(-4)
                              : '-'}
                          </span>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </>
              )}
              <br />
              <Row>
                <Col>
                  {employeeAdvanceData &&
                    !employeeAdvanceData.is_advance_permitted && (
                      <div className="alert alert-warning" role="alert">
                        <strong>Warning!</strong>{' '}
                        {employeeAdvanceData.permission_reason}
                      </div>
                    )}
                </Col>
              </Row>
              
            </CardBody>
          </Card>

          <Card className="shadow mt-3">
            <CardBody>
              <Row>
                <Col>
                  <span className="has-float-label">
                    <input
                      type="number"
                      name="amount_requested"
                      placeholder="Amount"
                      onChange={(event) =>
                        setAmountRequested(event.target.value)
                      }
                      className="form-control"
                      id="amount_requested"
                      required
                    />
                  </span>
                </Col>
                <Col>
                  <select
                    className="form-control"
                    name="status"
                    onChange={(event) => setStatus(event.target.value)}
                    onBlur={(event) => setStatus(event.target.value)}
                    required
                  >
                    <option value="pending" selected>
                      Pending
                    </option>
                    <option value="rejected">Rejected</option>
                    <option value="accepted">Accepted</option>
                    <option value="paid">Paid</option>
                  </select>
                </Col>
                <Col>
                  <select
                    className="form-control"
                    name="reason"
                    onChange={(event) => setReason(event.target.value)}
                    onBlur={(event) => setReason(event.target.value)}
                    required
                  >
                    {REASONS.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <button
                    type="submit"
                    className="btn btn-primary float-right"
                    onClick={createAdvance}
                    disabled={
                      !amountRequested ||
                      !status ||
                      !reason ||
                      isSubmitting ||
                      !employeeAdvanceData.is_advance_permitted
                    }
                  >
                    Add advance
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default EmployeeAdvanceAdd;
