import Swal from 'sweetalert2';

const handleApiSuccess = (title, response) => {
  Swal.fire({
    title,
    icon: 'success',
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
  });
};

const handleApiError = (title, errorFromApi) => {
  console.error(errorFromApi);
  Swal.fire({
    title,
    text: `${extractApiErrorMessage(errorFromApi)}`,
    icon: 'warning',
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
  });
};

const extractApiErrorMessage = (errorFromApi) => {
  // console.log(errorFromApi)
  let friendlyError = '';

  try {
    const errorResponse = errorFromApi.response;
    if (errorResponse.data) {
      // take the data in the response
      const responseData = errorResponse.data;
      friendlyError = Object.keys(responseData)
        .map((errorKey) => `${errorKey}: ${responseData[errorKey].join(',')}`)
        .join(',');
    } else {
      // otherwise at least try message
      friendlyError = errorResponse.message;
    }
  } catch (e) {
    //
  }

  if (!friendlyError) {
    friendlyError = 'Could not determine error';
  }

  return friendlyError;
};

export default {
  handleApiSuccess,
  handleApiError,
};
