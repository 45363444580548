import axios from 'axios';
import dayjs from "dayjs";
import dayOfYear from "dayjs/plugin/dayOfYear";
import isLeapYear from "dayjs/plugin/isLeapYear";
import { RRule } from "rrule";
import React, { useEffect } from 'react';
import { environment } from '../../../enviroment/enviroment';

dayjs.extend(dayOfYear);
dayjs.extend(isLeapYear);

type monthMinMaxDates = {
  minDate: Date;
  maxDate: Date;
};

interface recurrenceMinMaxDates {
  [index: string]: monthMinMaxDates;
}

export interface IBlockedDateEvent {
  id: string;
  title: string;
  start: Date;
  end: Date;
  allDay: boolean;
  rrule: string;
  block_reason: string;
  employees: IBlockedDateEmployees[] | null;
  recurring?: string;
}

export interface IBlockedDateEmployees {
  employee_id: number;
  employee_name: string;
  mobile_number: string;
  employee_id_number?: string;
  employee_number?: string;
}

export interface IOdpBlockDate {
  id: string;
  title: string;
  rrule: string;
  block_reason: string;
}

type IGroupedDateEmployeeResponse = {
  id: string;
  title: string;
  rrule: string;
  block_reason: string;
  employee_id: number;
  employee_name: string;
  employee_id_number?: string;
  employee_number?: string;
};

type IEmployeeGroupedDates = {
  [key: string]: IGroupedDateEmployeeResponse[];
};
type IEmployerOdpGroupedDate = {
  [key: string]: IOdpBlockDate;
};

export type IBlockedDatesResponse = {
  employees: IEmployeeGroupedDates;
  employer: IEmployerOdpGroupedDate;
};

interface EmployeeBlockDatesProps {
  employerId: number;
  employeeId: string;
}
interface IGroupedBlockedDateEvents {
  pastEvents: IBlockedDateEvent[];
  futureEvents: IBlockedDateEvent[];
  currentEvents: IBlockedDateEvent[];
}

export const getSplitBlockedDates = (
  events: IBlockedDateEvent[]
): IGroupedBlockedDateEvents => {
  const pastEvents: IBlockedDateEvent[] = [];
  const currentEvents: IBlockedDateEvent[] = [];
  const futureEvents: IBlockedDateEvent[] = [];

  const now = new Date();
  events.forEach((event) => {
    if (dayjs(event.end).isBefore(now)) {
      pastEvents.push(event);
    } else if (
      dayjs(event.start).isBefore(now) &&
      dayjs(event.end).isAfter(now)
    ) {
      currentEvents.push(event);
    } else {
      futureEvents.push(event);
    }
  });

  return {
    pastEvents,
    currentEvents,
    futureEvents,
  };
};

export const getBlockedDateEvent = (
  blockedDate: IOdpBlockDate,
  employees: IBlockedDateEmployees[] | null = null
): IBlockedDateEvent[] => {
  const EVENTS = [];
  const blockedDateInstance = blockedDate;

  if (blockedDate.rrule.indexOf("BYDAY=") > 0) {
    /*
        UPDATE STRING to accomodate https://www.npmjs.com/package/rrule/v/2.7.2#differences-from-icalendar-rfc
        RRule has no byday keyword. The equivalent keyword has been replaced by the byweekday keyword, 
        to remove the ambiguity present in the original keyword.
        */
    blockedDateInstance.rrule = blockedDate.rrule.replace(
      "BYDAY=",
      "BYWEEKDAY="
    );
  }

  const rrule = RRule.fromString(blockedDateInstance.rrule);
  const frequency = rrule.options.freq;
  const allRecurrenceInstances = rrule.all();

  let recurringText = '';
  switch (frequency) {
    case RRule.DAILY:
      recurringText = `Recurring daily`;
      break;
    case RRule.WEEKLY:
      const daysOfWeek = rrule.options.byweekday?.map(weekday => dayjs().day(weekday).format('dddd')).join(", ");
      recurringText = `Recurring weekly on day(s) ${daysOfWeek}`;
      break;
    case RRule.MONTHLY:
      const daysOfMonth = rrule.options.bymonthday?.join(", ");
      recurringText = `Recurring monthly on day(s) ${daysOfMonth}`;
      break;
    case RRule.YEARLY:
      const daysOfYear = rrule.options.byyearday?.join(", ");
      recurringText = `Recurring yearly on days ${daysOfYear}`;
      break;
    default:
      recurringText = '';
      break;
  }

  if (frequency === RRule.DAILY) {
    const firstDayInstance = allRecurrenceInstances[0];
    const lastDayInstance =
      allRecurrenceInstances[allRecurrenceInstances.length - 1];

    const event = {
      id: blockedDateInstance.id,
      title: blockedDateInstance.title,
      start: dayjs(firstDayInstance).startOf("day").toDate(),
      end: dayjs(lastDayInstance).endOf("day").toDate(),
      allDay: true,
      rrule: blockedDateInstance.rrule,
      block_reason: blockedDateInstance.block_reason,
      employees,
      recurring: recurringText,
    };
    EVENTS.push(event);
  } else if (frequency === RRule.YEARLY) {
    if (rrule.options.byyearday) {
      const byYearDaysLength = rrule.options.byyearday.length;

      for (
        let i = 0;
        i < allRecurrenceInstances.length;
        i += byYearDaysLength
      ) {
        // if leapYear pad by a day

        let startDate = allRecurrenceInstances.slice(
          i,
          i + byYearDaysLength
        )[0];
        let endDate = allRecurrenceInstances.slice(i, i + byYearDaysLength)[
          byYearDaysLength - 1
        ];
        const nthDayOfYear = dayjs(endDate).dayOfYear();

        const minNthDay = Math.min(...rrule.options.byyearday);
        const maxNthDay = Math.max(...rrule.options.byyearday);

        if (dayjs(startDate).isLeapYear() && minNthDay > 60) {
          startDate = dayjs(startDate).add(1, "day").toDate();
          endDate = dayjs(endDate).add(1, "day").toDate();
        } else if (
          dayjs(startDate).isLeapYear() &&
          nthDayOfYear === maxNthDay
        ) {
          startDate = dayjs(startDate).toDate();
          endDate = dayjs(endDate).add(1, "day").toDate();
        }

        const event = {
          id: blockedDateInstance.id,
          title: blockedDateInstance.title,
          start: dayjs(startDate).startOf("day").toDate(),
          end: dayjs(endDate).endOf("day").toDate(),
          allDay: true,
          rrule: blockedDateInstance.rrule,
          block_reason: blockedDateInstance.block_reason,
          employees,
          recurring: recurringText,
        };
        EVENTS.push(event);
      }
    } else {
      // only appears on 29th of feb
      allRecurrenceInstances.forEach((yearlyInstance: any) => {
        const event = {
          id: blockedDateInstance.id,
          title: blockedDateInstance.title,
          start: dayjs(yearlyInstance).startOf("day").toDate(),
          end: dayjs(yearlyInstance).endOf("day").toDate(),
          allDay: true,
          rrule: blockedDateInstance.rrule,
          block_reason: blockedDateInstance.block_reason,
          employees,
          recurring: recurringText,
        };
        EVENTS.push(event);
      });
    }
  } else if (frequency === RRule.MONTHLY) {
    const monthlyMinMaxPerYear: recurrenceMinMaxDates = {};

    for (let i = 0; i < allRecurrenceInstances.length; i += 1) {
      const index = `${allRecurrenceInstances[
        i
      ].getFullYear()}${allRecurrenceInstances[i].getMonth()}`;

      if (!monthlyMinMaxPerYear[index]) {
        monthlyMinMaxPerYear[index] = {
          minDate: allRecurrenceInstances[0],
          maxDate: new Date("1800"), // Intentional, maxDate will be updated below
        };
      }

      if (!monthlyMinMaxPerYear[index].maxDate) {
        monthlyMinMaxPerYear[index].maxDate = allRecurrenceInstances[i];
      } else {
        const maxDate =
          allRecurrenceInstances[i] > monthlyMinMaxPerYear[index].maxDate
            ? allRecurrenceInstances[i]
            : monthlyMinMaxPerYear[index].maxDate;
        monthlyMinMaxPerYear[index].maxDate = maxDate;
      }
    }
    Object.entries(monthlyMinMaxPerYear).forEach((keyValues) => {
      if (keyValues) {
        const event = {
          id: blockedDateInstance.id,
          title: blockedDateInstance.title,
          start: dayjs(keyValues[1].minDate).startOf("day").toDate(),
          end: dayjs(keyValues[1].maxDate).endOf("day").toDate(),
          allDay: true,
          rrule: blockedDateInstance.rrule,
          block_reason: blockedDateInstance.block_reason,
          employees,
          recurring: recurringText,
        };
        EVENTS.push(event);
      }
    });
  } else if (frequency === RRule.WEEKLY) {
    allRecurrenceInstances.forEach((weeklyInstance: any) => {
      const event = {
        id: blockedDateInstance.id,
        title: blockedDateInstance.title,
        start: dayjs(weeklyInstance).startOf("day").toDate(),
        end: dayjs(weeklyInstance).endOf("day").toDate(),
        allDay: true,
        rrule: blockedDateInstance.rrule,
        block_reason: blockedDateInstance.block_reason,
        employees,
        recurring: recurringText,
      };
      EVENTS.push(event);
    });
  }

  return EVENTS;
};


export const getAllBlockedDateEvents = (
  odpLimitsBlockedDates: IBlockedDatesResponse,
  employeeId: string
): IGroupedBlockedDateEvents => {
  const employeeEvents: IBlockedDateEvent[] = [];
  const employerEvents: IBlockedDateEvent[] = [];

  const { employer: employerBlockedDates, employees: employeeBlockedDates } =
    odpLimitsBlockedDates;


  if (employerBlockedDates) {
    Object.keys(employerBlockedDates).forEach((entry) => {
      employerEvents.push(...getBlockedDateEvent(employerBlockedDates[entry]));
    });
  }

  if (employeeBlockedDates) {
    Object.keys(employeeBlockedDates).forEach((entry) => {
      const employees: IBlockedDateEmployees[] = [];
      let employeeBlockedDate: IOdpBlockDate = {
        id: "",
        title: "",
        rrule: "",
        block_reason: "",
      };

      employeeBlockedDates[entry].forEach((blockedDate: any) => {
        employeeBlockedDate = {
          id: blockedDate.id,
          title: blockedDate.title,
          rrule: blockedDate.rrule,
          block_reason: blockedDate.block_reason,
        };
        employees.push({
          employee_id: blockedDate.employee_id,
          employee_name: blockedDate.employee_name,
          employee_id_number: blockedDate.employee_id_number,
          employee_number: blockedDate.employee_number,
          mobile_number: blockedDate.mobile_number,
        });
      });

      if (employees.some((employee) => employee.employee_id === parseInt(employeeId))) {
        employeeEvents.push(
          ...getBlockedDateEvent(employeeBlockedDate, employees)
        );
      }
    });
  }
  const blockdates = Array.from(
    new Map(
      [...employerEvents, ...employeeEvents].map((event) => [event.id, event])
    ).values()
  );
  const groupedBlockedDates = getSplitBlockedDates(blockdates);
  return groupedBlockedDates;
};

const EmployeeBlockDates: React.FC<{groupedBlockDates: IGroupedBlockedDateEvents}> = ({groupedBlockDates}: {groupedBlockDates: IGroupedBlockedDateEvents}) => {

  const getColor = (type: "current" | "past" | "future") => {
    switch (type) {
      case "past":
        return "#9ca6ae";
      case "current":
        return "#ED7687";
      default:
        return "rgba(6, 33, 51, 1)";
    }
  };
  return (
    <div className='container'>
      <br />
      <h1>Employee's Blocked Dates</h1>
      <br />
      {groupedBlockDates.currentEvents?.length > 0 && (
        <div>
          <h2>Today's active blocked dates</h2>
            {groupedBlockDates.currentEvents.map((event) => (
              <div className="card" key={event.id} style={{borderLeft: `3px solid ${getColor("current")}`, marginBottom: "4px"}}>
                <div className="card-body">
                  <h3>
                    {event.title} -{" "}
                    {event.employees
                    ? `Individual Block (${event.employees.length})`
                    : "Company Block"}
                  </h3>
                  <small>
                    {dayjs(event.start).format("DD MMM")} -{" "}
                    {dayjs(event.end).format("DD MMM YYYY")}
                    {event.recurring ? `(${event.recurring})` : ""}
                  </small>
                </div>
              </div>
            ))}
        </div>
      )}
      <br />
      {groupedBlockDates.futureEvents?.length > 0 && (
        <div>
          <h2>Future blocked dates</h2>
            {groupedBlockDates.futureEvents.map((event) => (
              <div className="card" key={event.id} style={{borderLeft: `3px solid ${getColor("future")}`, marginBottom: "4px"}}>
                <div className="card-body">
                  <h3>
                    {event.title} -{" "}
                    {event.employees
                    ? `Individual Block (${event.employees.length})`
                    : "Company Block"}
                  </h3>
                  <small>
                    {dayjs(event.start).format("DD MMM YYYY")} -{" "}
                    {dayjs(event.end).format("DD MMM YYYY")} {" "}
                    {event.recurring ? `(${event.recurring})` : ""}
                  </small>
                </div>
              </div>
            ))}
        </div>
      )}
      <br />
      {groupedBlockDates.pastEvents?.length > 0 && (
        <div>
          <h2>Past block dates (last 30 days only)</h2>
            {groupedBlockDates.pastEvents.map((event) => (
              <div className="card" key={event.id} style={{borderLeft: `3px solid ${getColor("past")}`, marginBottom: "4px"}}>
                <div className="card-body">
                  <h3>
                    {event.title} -{" "}
                    {event.employees
                    ? `Individual Block (${event.employees.length})`
                    : "Company Block"}
                  </h3>
                  <small>
                    {dayjs(event.start).format("DD MMM YYYY")} -{" "}
                    {dayjs(event.end).format("DD MMM YYYY")}
                    {event.recurring ? `(${event.recurring})` : ""}
                  </small>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default EmployeeBlockDates;