import React, { useContext, useState } from 'react';
import {
  Input,
  Button,
  Label,
  Col,
  Row,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
import Select from 'react-select';
import axios from 'axios';
import apiResponseHandler from '../../../utils/apiResponseHandler';
import FeatureFlags from '../../../utils/feature-flagging/FeatureFlags';
import { useEnabledFeatureFlag } from '../../../utils/feature-flagging/FeatureFlagProvider';
import { environment } from '../../../enviroment/enviroment';
import { EmployerReconContext } from './EmployerReconsContext';

function ReconInvoiceConfigurations(props) {
  const useAllReconConfigs = useEnabledFeatureFlag(
    FeatureFlags.ADMIN_PORTAL_AUTOMATED_ODP_INVOICING_USE_ALL_CONFIGS
  );

  const [searchTerm, setSearchTerm] = useState('');

  const existingPayCycleCalendarEvents = props.calendarEvents.map((cE) =>
    Number(cE.extendedProperties.private.employerPayCycleId)
  );

  const {
    eventState,
    updateEmployerPayCycle,
    updateReconConfigType,
    toggleIsTaxInclusive,
    toggleAddXeroInvoiceReference,
    updateXeroInvoiceReference,
    updateEmployerBranchXeroContactRelation,
    toggleAddBranchXeroReference,
    updateBranchXeroReference,
    updateXeroContact,
  } = useContext(EmployerReconContext);

  const [xeroContacts, setXeroContacts] = useState([]);

  const employerBranches = props.branches;

  const getXeroContacts = async () => {
    axios
      .get(
        `${environment.baseUrl}/xero_config/get_xero_contacts?searchTerm=${searchTerm}`
      )
      .then((res) => {
        setXeroContacts(res.data);
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error getting Xero Contacts', error);
      });
  };

  return (
    <div>
      <ListGroup>
        <ListGroupItem>
          <Row>
            <Col className="col-3">
              <strong className="float-right">Pay Cycle</strong>
            </Col>
            <Col className="col-8">
              <Select
                onChange={(eP) => updateEmployerPayCycle(eP.value)}
                options={props.payCycles.map((payCycle) => ({
                  value: payCycle.id,
                  label: `${payCycle.name}  |  ${payCycle.frequency} | ${payCycle.frequency_type}`,
                }))}
                // check if there are any events with paycycle id
                isOptionDisabled={(option) =>
                  existingPayCycleCalendarEvents.includes(option.value)
                }
              />
            </Col>
          </Row>
        </ListGroupItem>

        <ListGroupItem>
          <Row>
            <Col className="col-3">
              <strong className="float-right">Recon Config</strong>
            </Col>

            <Col className="col-2 ml-4">
              <Label>
                <Input
                  checked={eventState.reconConfigType === 'default'}
                  onChange={(e) => updateReconConfigType(e.target.value)}
                  style={{
                    width: '14x',
                    height: '14px',
                  }}
                  type="radio"
                  value="default"
                />
                Default
              </Label>
            </Col>

            {useAllReconConfigs && (
              <>
                <Col className="col-2">
                  <Label>
                    <Input
                      checked={
                        eventState.reconConfigType === 'lineItemsPerBranch'
                      }
                      onChange={(e) => updateReconConfigType(e.target.value)}
                      style={{
                        width: '14x',
                        height: '14px',
                      }}
                      type="radio"
                      value="lineItemsPerBranch"
                    />
                    Line Items Per Branch
                  </Label>
                </Col>

                <Col className="col-2">
                  <Label>
                    <Input
                      checked={
                        eventState.reconConfigType === 'invoicesPerBranch'
                      }
                      onChange={(e) =>
                        updateReconConfigType(e.target.value, employerBranches)
                      }
                      style={{
                        width: '14x',
                        height: '14px',
                      }}
                      type="radio"
                      value="invoicesPerBranch"
                    />
                    Invoices Per Branch
                  </Label>
                </Col>

                <Col className="col-3">
                  <Label>
                    <Input
                      checked={eventState.reconConfigType === 'groupInvoices'}
                      onChange={(e) =>
                        updateReconConfigType(e.target.value, employerBranches)
                      }
                      style={{
                        width: '14x',
                        height: '14px',
                      }}
                      type="radio"
                      value="groupInvoices"
                    />
                    Group Invoices with Same Account
                  </Label>
                </Col>
              </>
            )}
          </Row>
        </ListGroupItem>

        {eventState.reconConfigType && (
          <ListGroupItem>
            <Row>
              <Col className="col-3" />
              <Col className="col-5">
                <Input
                  style={{
                    fontSize: 16,
                    fontWeight: 'bold',
                  }}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Enter minimum of 3 characters to search"
                  type="text"
                  bsSize="md"
                  required
                />
              </Col>
              <Col className="col-3">
                <Button
                  outline
                  disabled={searchTerm.length < 3}
                  className="outline"
                  color="success"
                  onClick={() => getXeroContacts()}>
                  Search Xero Billing Accounts
                </Button>
              </Col>

              <Col className="col-3" />

              {xeroContacts.length > 0 && (
                <Col className="col-9 my-2">
                  <em>{xeroContacts.length} Results Found</em>
                </Col>
              )}
            </Row>
          </ListGroupItem>
        )}

        {/* Default & Lines Per Branch Config */}
        {xeroContacts.length > 0 &&
          (eventState.reconConfigType === 'default' ||
            eventState.reconConfigType === 'lineItemsPerBranch') && (
            <>
              <ListGroupItem>
                <Row>
                  <Col className="col-3">
                    <strong className="float-right">
                      Xero Billing Account
                    </strong>
                  </Col>
                  <Col className="col-8">
                    <Select
                      onChange={(xeroContact) =>
                        updateXeroContact(xeroContact.value, xeroContact.label)
                      }
                      options={xeroContacts.map((xC) => ({
                        value: xC.ContactID,
                        label: xC.Name,
                      }))}
                    />
                  </Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col className="col-3 ml-4" />
                  <Col className="col-8">
                    <Input
                      checked={eventState.isTaxInclusive}
                      onChange={() => toggleIsTaxInclusive()}
                      type="checkbox"
                    />
                    <Label>Amounts are TAX INCLUSIVE</Label>
                  </Col>
                </Row>
              </ListGroupItem>

              <ListGroupItem>
                <Row>
                  <Col className="col-3 ml-4" />
                  <Col className="col-8">
                    <Input
                      checked={eventState.addXeroInvoiceReference}
                      onChange={() => toggleAddXeroInvoiceReference()}
                      type="checkbox"
                    />
                    <Label>Add Xero Invoice Reference</Label>
                  </Col>
                </Row>
              </ListGroupItem>

              {eventState.addXeroInvoiceReference && (
                <ListGroupItem>
                  <Row>
                    <Col className="col-3">
                      <strong className="float-right">
                        Xero Invoice Reference
                      </strong>
                    </Col>
                    <Col className="col-8">
                      <Input
                        style={{
                          fontSize: 16,
                          fontWeight: 'bold',
                        }}
                        onChange={(e) =>
                          updateXeroInvoiceReference(e.target.value)
                        }
                        type="text"
                        bsSize="md"
                        required
                      />
                    </Col>
                  </Row>
                </ListGroupItem>
              )}
            </>
          )}

        {/* Custom Config */}
        {eventState.reconConfigType === 'invoicesPerBranch' &&
          xeroContacts.length > 0 && (
            <ListGroupItem>
              <Row className="">
                <Col className="col-4">
                  <strong className="float-right">Branches</strong>
                </Col>
                <Col className="col-4">
                  <strong className="justify-content-left">
                    Xero Billing Account
                  </strong>
                </Col>
                <Col className="col-3">
                  <strong className="float-left">
                    Add Xero Invoice Reference
                  </strong>
                </Col>
              </Row>

              {eventState.employerBranchXeroContactRelation.map((bXC) => (
                <div className="my-2" key={bXC.branchId}>
                  <Row>
                    <Col className="col-4 my-2">
                      <span className="float-right">
                        {bXC.name} {bXC.code}
                      </span>
                    </Col>
                    <Col className="col-4 my-2">
                      <Select
                        onChange={(xC) =>
                          updateEmployerBranchXeroContactRelation(
                            bXC.branchId,
                            xC.value
                          )
                        }
                        options={xeroContacts.map((xC) => ({
                          value: xC.ContactID,
                          label: xC.Name,
                        }))}
                      />
                    </Col>
                    <Col className="col-3 m-2">
                      {bXC.xeroContactId && (
                        <Input
                          checked={bXC.addXeroInvoiceReference}
                          onChange={(e) =>
                            toggleAddBranchXeroReference(
                              bXC.branchId,
                              e.target.checked
                            )
                          }
                          type="checkbox"
                        />
                      )}

                      {bXC.addXeroInvoiceReference && (
                        <Input
                          className="mx-2"
                          style={{
                            fontSize: 14,
                            fontWeight: 'bold',
                          }}
                          onChange={(e) =>
                            updateBranchXeroReference(
                              bXC.branchId,
                              e.target.value
                            )
                          }
                          type="text"
                          bsSize="sm"
                          required
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              ))}
            </ListGroupItem>
          )}
      </ListGroup>
    </div>
  );
}

export default ReconInvoiceConfigurations;
